//https://cafe.bet25.dk Pavel's test
//https://cms.bet25.dk Pavel's live
//https://dev.bet25.dk Pavel's (cafe)
//https://api.bet25.dk Tom's

import env from 'utils/env'

import { DOMAIN_PATH, LOCAL_HOST_IP } from 'configs/main'
//Content API
export function getBetApiUrl() {
    if (window.location.hostname.indexOf('dev') > -1) {
        return 'https://api.bet25dev.dk'
    } else {
        switch (window.location.hostname) {
            case LOCAL_HOST_IP:
                return ''
            case 'localhost':
            case 'bs-local.com':
            case 'bet25dev.dk':
            case 'www.bet25dev.dk':
                return 'https://api.bet25dev.dk'
            case 'bet25test.dk':
                return 'https://api.bet25test.dk'
            default:
                return 'https://api.bet25.dk'
        }
    }
}

export function getCMSPath() {
    if (window.location.hostname.indexOf('dev') > -1) {
        return 'https://cms.bet25dev.dk'
    } else {
        switch (window.location.hostname) {
            case 'localhost':
            case 'bs-local.com':
            case LOCAL_HOST_IP:
            case 'bet25dev.dk':
            case 'www.bet25dev.dk':
                return 'https://cms.bet25dev.dk'
            case 'bet25test.dk':
            default:
                return 'https://cms.bet25.dk'
        }
    }
}

// export const CMS_PATH = 'https://cms.bet25.dk'
export const CMS_PATH = getCMSPath()

export const CONTENT_API_BASE_URL = `${getBetApiUrl()}/util/`

export const MAIN_REST_BASE_API =
    window.location.hostname === 'localhost' ||
    window.location.hostname === LOCAL_HOST_IP ||
    window.location.hostname === 'bs-local.com'
        ? '/rod25/'
        : CONTENT_API_BASE_URL + 'rod25/'

export function getApiUrl() {
    if (window.location.hostname.indexOf('dev') > -1) {
        return 'https://api.bet25dev.dk/casino'
    } else {
        switch (window.location.hostname) {
            case 'localhost':
            case 'bs-local.com':
                return ''
            case LOCAL_HOST_IP:
                return ''
            case 'bet25dev.dk':
            case 'www.bet25dev.dk':
                return 'https://api.bet25dev.dk/casino'
            case 'bet25test.dk':
                return 'https://api.bet25test.dk/casino'
            default:
                return 'https://api.bet25.dk/casino'
        }
    }
}

export const GAME_LAUNCH_API = `${getApiUrl()}/games/`
export const API_URL = `${getApiUrl()}/v2/`
export const API_URL_OLD = `${getApiUrl()}/`

export const CMS_REST_BASE_URL = `${env('CMS_HOST', DOMAIN_PATH)}/rest/`
export const CMS_API_BASE_URL = `${env('CMS_HOST', DOMAIN_PATH)}/rod/rest/`
export const API_PHP = 'api.php'
export const CONTENT_API_PHP = 'casinogo/api.php'
export const CONTENT_NEWSLETTER_API = 'casinogo/newsletter/api.php'
export const AUTH_REST_PATH = `${getBetApiUrl()}/punter/`
